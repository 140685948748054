import { Button } from "components/Button";
import { TextField } from "components/Forms/TextField";
import { useViewModel } from "./viewModel";
import { ChatIntegration } from "models/chatIntegration";
import { SaveOrUpdateChatIntegrationFunction } from "../../viewModel";
import { MultiSelect } from "components/Forms/MultiSelect";
import { Select } from "components/Forms/Select";

type Props = {
  provider: string;
  selectedIntegration: ChatIntegration | undefined;
  onClose: () => void;
  onSave: SaveOrUpdateChatIntegrationFunction;
};

export const MatrixForm = ({
  selectedIntegration,
  onClose,
  onSave,
  provider,
}: Props) => {
  const viewModel = useViewModel(selectedIntegration, onSave, provider);

  if (viewModel.step === 0) {
    return (
      <form
        onSubmit={viewModel.handleSubmit(viewModel.handleSubmitFormFirstStep)}
        className="flex flex-col gap-5 max-h-96 overflow-y-auto"
      >
        <TextField
          label="Nome da integração"
          placeholder="Digite o nome da integração"
          error={viewModel.errors.name?.message}
          register={viewModel.register("name", {
            required: { value: true, message: "O nome é obrigatório" },
          })}
          variant="secondary"
        />
        <TextField
          label="Domínio Matrix"
          placeholder="Digite o seu domínio personalizado do Matrix"
          error={viewModel.errors.matrix?.host?.message}
          register={viewModel.register("matrix.host", {
            required: { value: true, message: "O domínio é obrigatório" },
          })}
          variant="secondary"
        />
        <TextField
          label="Login do usuário"
          placeholder="Digite o login de acesso"
          error={viewModel.errors.matrix?.login?.message}
          register={viewModel.register("matrix.login", {
            required: { value: true, message: "O login é obrigatório" },
          })}
          variant="secondary"
          helpText="Esse login é o associado a chave de acesso"
        />
        <TextField
          label="Chave de acesso"
          placeholder="Digite o a chave de acesso"
          error={viewModel.errors.matrix?.key?.message}
          register={viewModel.register("matrix.key", {
            required: { value: true, message: "A chave é obrigatória" },
          })}
          variant="secondary"
          helpText="Essa é a chave de acesso fornecida pela Matrix ao requisitar ao atendimento deles"
        />
        <TextField
          label="Departamento (Opcional)"
          placeholder="Digite o departamento"
          error={viewModel.errors.matrix?.department?.message}
          register={viewModel.register("matrix.department")}
          variant="secondary"
          helpText="Se preenchido a integração buscará apenas lead desse departamento. Porém ainda irá fazer a atribuição de vendedor automática normalmente"
        />
        <TextField
          label="Conta do usuário (Opcional)"
          placeholder="Digite a conta Matrix"
          error={viewModel.errors.matrix?.account?.message}
          register={viewModel.register("matrix.account")}
          variant="secondary"
          helpText="Esse campo deve ser usado apenas quando o cliente possuir mais de uma conta Matrix no mesmo grupo"
        />
        <div className="modalButtons">
          <Button onClick={onClose} theme="cancel">
            Cancelar
          </Button>
          <Button loading={viewModel.loadingForm} theme="highlight">
            Salvar
          </Button>
        </div>
      </form>
    );
  }

  if (viewModel.step === 2) {
    return (
      <form
        onSubmit={viewModel.handleSubmit(viewModel.handleSubmitFormThirdStep)}
        className="flex flex-col gap-5 overflow-y-auto max-h-[calc(100svh-384px)]"
      >
        {viewModel.selectedSellersOptions.map((seller, index) => (
          <div key={seller.id}>
            <Select
              label={`Associe ${seller.name}`}
              onChange={(value) => {
                // Check if the internal seller is already select on the list
                const relatedSeller = viewModel.selectedSellersRelated.find(
                  (relatedSeller) => relatedSeller.seller === value
                );

                // Get current external seller
                const seller = viewModel.selectedSellersOptions[index];

                // Check if the external seller is already select on the list
                const sellerOnTheList = viewModel.selectedSellersRelated.find(
                  (relatedSeller) =>
                    relatedSeller.externalSeller === seller.name
                );

                // If external seller is selected on the list, change the internal seller related to the new selection
                if (sellerOnTheList) {
                  viewModel.setValue("matrix.sellersRelated", [
                    ...viewModel.selectedSellersRelated.filter(
                      (relatedSeller) =>
                        relatedSeller.externalSeller !== seller.name
                    ),
                    {
                      seller: value!,
                      externalSeller: seller.name,
                    },
                  ]);
                  return;
                }

                // If the internal seller selected is on the list, change they to the current external seller
                if (relatedSeller) {
                  viewModel.setValue("matrix.sellersRelated", [
                    ...viewModel.selectedSellersRelated.filter(
                      (relatedSeller) => relatedSeller.seller !== value!
                    ),
                    {
                      seller: value!,
                      externalSeller: seller.name,
                    },
                  ]);
                  return;
                }

                // If the internal and external seller isn't selected push it
                viewModel.setValue("matrix.sellersRelated", [
                  ...viewModel.selectedSellersRelated,
                  {
                    seller: value!,
                    externalSeller: seller.name,
                  },
                ]);
              }}
              options={viewModel.internalSellers.map((internalSeller) => ({
                value: internalSeller.id,
                label: internalSeller.name,
              }))}
              selected={
                viewModel.selectedSellersRelated.find(
                  (sellerRelated) =>
                    sellerRelated.externalSeller === seller.name
                )?.seller
              }
              error={viewModel.errors.type?.message}
              variant="secondary"
            />
          </div>
        ))}
        <div className="modalButtons">
          <Button onClick={onClose} theme="cancel">
            Cancelar
          </Button>
          <Button
            loading={viewModel.loadingForm}
            theme="highlight"
            className="px-7"
          >
            Salvar
          </Button>
        </div>
      </form>
    );
  }

  return (
    <form
      onSubmit={viewModel.handleSubmit(viewModel.handleSubmitFormSecondStep)}
      className="flex flex-col gap-5"
    >
      <MultiSelect
        label="Selecione os vendedores"
        onChange={(value) => viewModel.setValue("matrix.sellers", value)}
        options={viewModel.sellers.map((seller) => ({
          value: seller.name,
          label: seller.name,
        }))}
        selected={viewModel.selectedSellers}
        error={viewModel.errors.matrix?.sellers?.message}
        variant="secondary"
      />
      <div className="modalButtons">
        <Button onClick={onClose} theme="cancel">
          Cancelar
        </Button>
        <Button loading={viewModel.loadingForm} theme="highlight">
          Salvar
        </Button>
      </div>
    </form>
  );
};
