import { CodeResponse, useGoogleLogin } from "@react-oauth/google";
import { AxiosError } from "axios";
import { Button } from "components/Button";
import { Select } from "components/Forms/Select";
import { TextField } from "components/Forms/TextField";
import { GoogleCampaignOption } from "models/campaign";
import { Integration } from "models/integration";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  getGoogleAccounts,
  getGoogleAccountsByCode,
} from "services/integrationService";
import { SaveOrUpdateCampaignsIntegrationFunction } from "../../viewModel";

export type GoogleIntegrationV2Form = {
  type: string;
  name: string;
  adsId: string;
  sheetId?: string;
};

type Props = {
  selectedIntegration: Integration | undefined;
  onSaveOrUpdate: SaveOrUpdateCampaignsIntegrationFunction;
  onClose: () => void;
};

export const GoogleFormV2 = ({
  selectedIntegration,
  onClose,
  onSaveOrUpdate,
}: Props) => {
  const {
    register,
    formState: { errors },
    setValue,
    handleSubmit,
    reset,
    watch,
    setError,
  } = useForm<GoogleIntegrationV2Form>();
  const { t } = useTranslation();

  const [isGettingGoogleInfo, setIsGettingGoogleInfo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [accounts, setAccounts] = useState<GoogleCampaignOption[]>();

  const adsId = watch("adsId", "");

  const onGoogleSignInSucces = async (
    result: Omit<CodeResponse, "error" | "error_description" | "error_uri">
  ) => {
    const { code } = result;
    setIsGettingGoogleInfo(true);

    try {
      const accounts = await getGoogleAccountsByCode(code);

      setAccounts(accounts);
    } catch (error) {
      const _error = error as AxiosError;

      toast.error("Erro ao fazer login com o Google: " + _error.message);
    }

    setIsGettingGoogleInfo(false);
  };

  const onGoogleSignInFail = (
    result: Pick<CodeResponse, "error" | "error_description" | "error_uri">
  ) => {
    toast.error("Erro ao fazer login com o Google: " + result.error);
  };

  const login = useGoogleLogin({
    onSuccess: onGoogleSignInSucces,
    onError: onGoogleSignInFail,
    scope: "https://www.googleapis.com/auth/adwords",
    flow: "auth-code",
  });

  useEffect(() => {
    register("type", {
      required: {
        value: true,
        message: t("providers.view.integrations.form.validation.invalid_type"),
      },
    });
  }, [register]);

  useEffect(() => {
    reset({
      name: selectedIntegration?.name ?? "",
      adsId: selectedIntegration?.adsId ?? "",
      sheetId: selectedIntegration?.sheetId ?? "",
      type: selectedIntegration?.type ?? "googlev2",
    });
  }, [selectedIntegration, reset]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      toast.info("Buscando contas de anúncio...");

      getGoogleAccounts()
        .then((accounts) => {
          toast.success("Contas de anúncio encontradas");
          setAccounts(accounts);
        })
        .catch((error) => {
          console.log(error);
          toast.error("Ocorreu um erro, por favor faça login novamente");
        });
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const handleRefreshAccounts = () => {
    toast.info("Buscando contas de anúncio...");
    getGoogleAccounts(true)
      .then((accounts) => {
        toast.success("Contas de anúncio encontradas");
        setAccounts(accounts);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Ocorreu um erro, por favor faça login novamente");
      });
  };

  const handleSubmitForm = async (data: GoogleIntegrationV2Form) => {
    if (isLoading) return;

    if (!data.adsId) {
      setError("adsId", {
        message: "Selecione a conta de anúncio",
      });
      return;
    }

    const googleCampaignAccount = accounts?.find(
      (account) => account.value === data.adsId
    );

    if (!googleCampaignAccount) {
      return;
    }

    setIsLoading(true);
    try {
      toast.info(
        selectedIntegration
          ? t("providers.view.integrations.form.update_loading")
          : t("providers.view.integrations.form.creating_loading")
      );
      await onSaveOrUpdate({
        ...data,
        sheetId: googleCampaignAccount?.managerId,
      });
      toast.success(
        selectedIntegration
          ? t("providers.view.integrations.form.update_success")
          : t("providers.view.integrations.form.creating_loading")
      );
      onClose();
    } catch (error) {
      toast.error(t("providers.view.integrations.form.error"));
    }
    setIsLoading(false);
  };

  return (
    <form
      onSubmit={handleSubmit(handleSubmitForm)}
      className="flex flex-col gap-5"
    >
      <Button
        type="button"
        onClick={() => login()}
        loading={isGettingGoogleInfo}
      >
        Entrar novamente com o Google
      </Button>
      {accounts && (
        <>
          <TextField
            label={t("providers.view.integrations.form.fields.account_name")}
            placeholder="Nome da integração"
            error={errors.name?.message}
            register={register("name", {
              required: {
                value: true,
                message: t(
                  "providers.view.integrations.form.fields.account_name_required"
                ),
              },
            })}
            variant="secondary"
          />
          <Select
            onChange={(value) => setValue("adsId", value!)}
            options={accounts}
            variant="secondary"
            selected={adsId}
            placeholder="Selecione a conta de anúncio"
          />
          <Button type="button" onClick={handleRefreshAccounts}>Atualizar contas</Button>
        </>
      )}
      <div className="modalButtons">
        <Button onClick={onClose} theme="cancel">
          {t("actions.cancel")}
        </Button>
        <Button loading={isLoading} theme="highlight">
          {selectedIntegration ? t("actions.edit") : t("actions.add")}
        </Button>
      </div>
    </form>
  );
};
