import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";

import {
  ChatIntegration,
  ChatIntegrationSellerRelated,
  ChatIntegrationType,
} from "models/chatIntegration";
import { AxiosError } from "axios";
import { messageToError, ValidationError } from "utils/validation";
import { toast } from "react-toastify";
import { getIntegrationSellers } from "services/chatIntegrationService";
import { SaveOrUpdateChatIntegrationFunction } from "../../viewModel";
import { IntegrationSeller } from "models/integrationSeller";
import { getSellers } from "services/sellerService";
import { Seller } from "models/seller";

export type MatrixIntegrationForm = {
  type: ChatIntegrationType;
  name: string;
  matrix: {
    host: string;
    sellers: string[];
    sellersRelated: ChatIntegrationSellerRelated[];
    login: string;
    key: string;
    account?: string;
    department?: string;
  };
};

export const useViewModel = (
  selectedIntegration: ChatIntegration | undefined,
  handleSaveOrUpdate: SaveOrUpdateChatIntegrationFunction,
  provider: string
) => {
  const {
    register,
    formState: { errors },
    setValue,
    handleSubmit,
    setError,
    watch,
  } = useForm<MatrixIntegrationForm>();

  const [step, setStep] = useState(0);
  const [loadingForm, setLoadingForm] = useState(false);
  const [sellers, setSellers] = useState<IntegrationSeller[]>([]);
  const [internalSellers, setInternalSellers] = useState<Seller[]>([]);

  const selectedSellers = watch("matrix.sellers") ?? [];
  const selectedSellersRelated = watch("matrix.sellersRelated") ?? [];

  const selectedSellersOptions = useMemo(() => {
    return selectedSellers.map(
      (seller) => sellers.find((_seller) => _seller.name === seller)!
    );
  }, [sellers, selectedSellers]);

  useEffect(() => {
    register("type", {
      required: { value: true, message: "O tipo de integração é obrigatório" },
    });
  }, [register]);

  useEffect(() => {
    setValue("name", selectedIntegration?.name ?? "");
    setValue("type", selectedIntegration?.type ?? "matrix");
    setValue("matrix.host", selectedIntegration?.matrix?.host ?? "");
    setValue("matrix.sellers", selectedIntegration?.matrix?.sellers ?? []);
    setValue("matrix.account", selectedIntegration?.matrix?.account ?? "");
    setValue("matrix.login", selectedIntegration?.matrix?.login ?? "");
    setValue("matrix.key", selectedIntegration?.matrix?.key ?? "");
    setValue("matrix.department", selectedIntegration?.matrix?.department ?? "");
    setValue(
      "matrix.sellersRelated",
      selectedIntegration?.matrix?.sellersRelated ?? []
    );
  }, [selectedIntegration, setValue]);

  async function handleSubmitFormFirstStep(data: MatrixIntegrationForm) {
    if (loadingForm) {
      return;
    }
    setLoadingForm(true);

    try {
      toast.info("Verificando integração...");
      const { attendents } = await getIntegrationSellers({
        type: data.type,
        matrix: {
          host: data.matrix.host,
          login: data.matrix.login,
          key: data.matrix.key,
        },
      });
      setSellers(attendents);

      if (selectedIntegration && selectedIntegration.matrix?.sellers) {
        const filteredSellers = selectedIntegration.matrix.sellers.filter(
          (seller) => {
            return attendents.some((_seller) => _seller.name === seller);
          }
        );

        setValue("matrix.sellers", filteredSellers);
      }
      toast.success("Integração verificada com sucesso!");
      setStep(1);
    } catch (error) {
      toast.error(errorMessage(error));
    }
    setLoadingForm(false);
  }

  const errorMessage = (error: unknown) => {
    const _err = error as AxiosError;
    if (_err.isAxiosError && _err.response?.data !== undefined) {
      const data = _err.response.data as {
        errors: ValidationError[] | undefined;
      };

      if (data.errors) {
        const fields = data.errors.map((error) => {
          setError(error.key as keyof MatrixIntegrationForm, {
            message: messageToError(error.message),
          });
          return error.key;
        });
        return `Os campos ${fields.join(", ")} são inválidos`;
      }
      if (_err.response.status === 400)
        return "O domínio ou o token são inválidos";
    }
    return "Ocorreu um erro, por favor tente novamente";
  };

  async function handleSubmitFormSecondStep() {
    if (loadingForm) return;
    setLoadingForm(true);

    const internalSellers = await getSellers(1, 100, provider);
    setInternalSellers(internalSellers.data);

    setLoadingForm(false);

    setStep(2);
  }

  async function handleSubmitFormThirdStep(data: MatrixIntegrationForm) {
    if (loadingForm) {
      return;
    }
    setLoadingForm(true);

    const valueOrUndefined = (value: string | undefined) => {
      if (value === undefined || value === null || value.trim() === "") {
        return undefined;
      }
      return value;
    };

    const account = valueOrUndefined(data.matrix.account);
    const department = valueOrUndefined(data.matrix.department);

    await handleSaveOrUpdate({
      ...data,
      matrix: {
        ...data.matrix,
        account,
        department,
        sellersRelated: data.matrix.sellersRelated.filter((sellerRelated) =>
          data.matrix.sellers.some(
            (seller) => seller === sellerRelated.externalSeller
          )
        ),
      },
    });

    setLoadingForm(false);
  }

  return {
    selectedIntegration,
    internalSellers,
    setValue,
    register,
    errors,
    loadingForm,
    handleSubmit,
    handleSubmitFormFirstStep,
    handleSubmitFormSecondStep,
    handleSubmitFormThirdStep,
    step,
    sellers,
    selectedSellers,
    selectedSellersOptions,
    selectedSellersRelated,
  };
};
